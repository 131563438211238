<template>
    <div class="container">
        <div class="panel">
            <div class="two">
                <News :images="images" :list="news" v-if="news.length > 0" />
            </div>

            <Card title="天气预报" :extra='false'>
                <img style="width: 100%; cursor: pointer;" v-if="weather.length > 0" :src="weather[0].face_img" alt=""
                    @click="openArticleDetail(weather[0])">
            </Card>
            <div class="two">
                <Images :list="clearImages" />
            </div>

            <Story :list="storyList" />

            <Card title="科普短片">
                <img style="width: 100%; cursor: pointer;" v-if="tecVideo.length > 0" :src="tecVideo[0].face_img" alt=""
                    @click="openArticleDetail(tecVideo[0])">
            </Card>

            <Card title="热点视频">
                <img style="width: 100%; cursor: pointer;" v-if="hotVideo.length > 0" :src="hotVideo[0].face_img" alt=""
                    @click="openArticleDetail(hotVideo[0])">
            </Card>

        </div>





    </div>
</template>

<script>
import article from '@/mixins/article'
import News from '@/components/news'
import Images from '@/components/images'
import Story from '@/components/person-story'

import { fetchList } from '@/api/article'

export default {
    mixins: [article],
    components: {
        News,
        Images,
        Story,
    },

    data() {
        return {
            images: [],
            news: [],
            weather: [],
            clearImages: [],
            storyList: [],
            tecVideo: [],
            hotVideo: [],
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "qxxw",
                    page: 1,
                    rows: 3,
                    face: 1
                },
                    {
                        category: 'qxxw',
                        page: 1,
                        rows: 7,
                },
                    {
                        category: "tqyb",
                        page: 1,
                        rows: 1,
                    }, {
                        // 高清图集
                        category: 'zrfg',
                        page: 1,
                        rows: 5,
                        face: 1
                    },
                    {
                        // 人物故事
                        category: "rwgs",
                        page: 1,
                        rows: 10,
                    },
                    {
                        category: 'kpdp',
                        page: 1,
                        rows: 1,
                    }, {
                        category: 'rdsp',
                        page: 1,
                        rows: 1,
                    },
              
                ]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0 ) {
                    if (res[0].data && Array.isArray(res[0].data.list)) {
                        this.images = res[0].data.list;
                    }
                    if (res[1].data && Array.isArray(res[1].data.list)) {
                        this.news = res[1].data.list;
                    }
                    if (res[2].data && Array.isArray(res[2].data.list)) {
                        this.weather = res[2].data.list;
                    }
                    if (res[3].data && Array.isArray(res[3].data.list)) {
                        this.clearImages = res[3].data.list;
                    }
                    if (res[4].data && Array.isArray(res[4].data.list)) {
                        this.storyList = res[4].data.list;
                    }
                    if (res[5].data && Array.isArray(res[5].data.list)) {
                        this.tecVideo = res[5].data.list;
                    }
                    if (res[6].data && Array.isArray(res[6].data.list)) {
                        this.hotVideo = res[6].data.list;
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
</style>