<template>
    <div class="story">
        <Card title="人物故事" url='/character-story'>
            <div class="wrapper">
                <div class="center clickable arrow prev" @click="prev">
                    <i class="iconfont icon-arrow-right"></i>
                </div>
                <div class="center clickable arrow next" @click="next">
                    <i class="iconfont icon-arrow-right"></i>
                </div>

                <img style="width: 100%" :src="active.face_img" alt="">
                <div class="title">
                    {{ active.title }}
                </div>
                <div class="footer">{{active.brief}}</div>
            </div>

        </Card>
    </div>

</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            activeIndex: 0,
        }
    },
    computed: {
        active() {
            return this.list[this.activeIndex] ? this.list[this.activeIndex] : {}
        }
    },
    methods: {
        prev() {
            if (this.activeIndex !== 0) {
                this.activeIndex--;
            }
        },
        next() {
            if (this.activeIndex < this.list.length - 1) {
                this.activeIndex++
            }
        }
    }
}
</script>

<style lang="less" scoped>
.story {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 4;
    background-color: #fff;
}

.wrapper {
    position: relative;

    .arrow {
        position: absolute;
        top: 80px;
        background-color: rgba(0, 00, 0, .8);
        color: #fff;
        height: 48px;
        width: 24px;
        font-weight: bold;
        font-size: 24px;
    }

    .prev {
        left: 0;
        transform: rotate(-180deg);
    }

    .next {
        right: 0;
    }
}

.title {
    font-weight: bold;
    margin-top: 8px;
    line-height: 2em;
}
.footer {
    margin-top: 8px;
    font-size: 13px;
    color: #666;
    line-height: 2em;
}
</style>