<template>
    <Card title="高清图集" url='/article-category?name=高清图集'>

        <div class="images" v-if="list.length > 0">
            <div class="item first" @click="openArticleDetail(list[0])">
                <img :src="list[0].face_img" alt="">
                <div class="title">{{list[0].title}}</div>
            </div>
            <div class="item" v-for="item in list.filter((item, index) => index !== 0)" :key="item.code"
                @click="openArticleDetail(item)">
                <img :src="item.face_img" alt="">
                <div class="title">
                    {{item.title}}</div>
            </div>
        </div>
    </Card>
</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="less" scoped>
.images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;

    .first {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .item {
        cursor: pointer;
        position: relative;
    img {
        width: 100%;
    }
    .title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 4px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
    }
    }


}
</style>