<template>
    <Card title="新闻" url='/article-category?name=新闻'>
        <div class="wrapper">
            <div class="swiper news">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item) in images" :key="item.code">
                        <img :src="item.face_img" alt="" @click="openArticleDetail(item)">
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>

            <div class="list">
                <a href="#" class="item" v-for="item in list" :key="item.code" @click.prevent="openArticleDetail(item)">
                    <div class="content">{{ item.title }}</div>
                </a>
            </div>

        </div>

    </Card>
</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],

    props: {
        images: {
            type: Array,
            default: () => []
        },
        list: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {

        }
    },
        mounted() {

    //   var swiper = 
      new window.Swiper(".news", {
        spaceBetween: 8,
        centeredSlides: true,
         loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
      });

    },

}
</script>

<style lang="less" scoped>



.wrapper {
    display: flex;
}

      .swiper {
        width: 300px;
             height: 220px;

                   .swiper-slide img {
                    cursor: pointer;
          width: 100%;
        display: block;
      }
      }



.list {
    flex: 1;
    margin-left: 24px;
    .item {
        display: block;
                margin-bottom: 12px;
        position: relative;
        left: 12px;

        .content {
        overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 290px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: -12px;
            width: 5px;
            height: 5px;
            background-color: #999;
            border-radius: 50%;
        }

    }
}
</style>